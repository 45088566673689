@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.header__top {
  padding: 7px 0px;
  background: #1a1a29;
  border-bottom: 1px solid #c393416a;
  color: #c39341e5;
}

.header__top__left {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__left a{
  text-decoration: none;
  color: #faba1abc;
  font-weight: bold;
}
.header__top__left a:hover{
  color: #ffb700;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.header__top__help  i {
  background: #c39341e5;
  color: #1a1a29;
  padding: 4px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__top__right a {
  text-decoration: none;
  font-size: 1rem;
  background: #c39341e5;
  color: #1a1a29;
  padding: 2px 6px;
  border-radius: 50px;
  font-weight: 600;
}

.wp__button {
  animation: shake 3s; 
  animation-iteration-count: infinite; 
}

.call__button {
  animation: shake 3s; 
  animation-iteration-count: infinite; 
}
.header__top__right a:hover {
  color: #1a1a29;
  animation: shake 10s; 
  animation-iteration-count: infinite; 
}

/* ============ header middle style =========== */

.header__middle {
  padding: 10px 0px;
  background: #1a1a29eb;
  border-bottom: 1px solid #c393416a;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #c39341e5;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #c39341e5;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #c39341e5;
}

.header__location-content h6 {
  font-weight: 600;
  color: #c39341e5;
}

.header__btn {
  background: #c39341e5 !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 50%;
  animation: shake 5s; 
  animation-iteration-count: infinite; 
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #fff;
  justify-content: flex-end;
  font-size: 0.9rem;
  
}

.header__btn:hover {
  background: #c38a41 !important;
  animation: shake 20s; 
  animation-iteration-count: infinite; 
}

.header__btn a:hover  {
  color: #fff;
  background: #c38a41 !important;
}

/* ================ main navbar styles =========== */

.main__navbar {
  padding: 15px 0px;
  background: #1a1a29;
  border-bottom: 1px solid #c393416a;
}

.mobile__menu i {
  color: #c39341e5;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.mobile__header__right a{
  text-decoration: none;
  font-size: 0.9rem;
  background: #c39341e5;
  color: #1a1a29;
  padding: 2px 6px;
  border-radius: 50px;
  font-weight: 600;
  display: none !important;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 5rem;
  background: #1a1a29 !important;
}

.menu .logo {
  display: none;
}

.nav__item {
  color: #fff !important;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #f9a826 !important;
}

.nav__active {
  color: #f9a826;
}

@media only screen and (max-width: 768px) {
  .header__top,
  .header__middle {
    display: none;
  }

  .mobile__menu {
    display: block;
    justify-content: start;
    align-items: start;
  }

  .mobile__header__right a{
    display: block !important;
  }

  .menu .logo {
    display: block;
    margin-top: -50%;
  }
  .navigation {
    background: rgba(22, 26, 61, 0.459);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #1a1a22;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }
}

@media only screen and (max-width: 576px){
  .main__navbar{
    padding: 8px 0px;
  }

  .menu {
    width: 200px;
  }
}