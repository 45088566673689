.reservation__completed-modal{
    max-width: 30% !important;
    margin-right: 35% !important;
    margin-left: 35% !important;
}


.completed__img {
    width: 30%;
    margin-right: 35%;
    margin-left: 35%;
    height: 6rem !important;
}

.reservation__completed-ok {
    background: #c39341e5 !important;
    color: #fff !important;
}


@media only screen and (max-width: 768px) {
    .reservation__completed-modal{
        max-width: 40% !important;
        margin-right: 30% !important;
        margin-left: 30% !important;
    }

    .modal-title {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 576px) {
    .reservation__completed-modal{
        max-width: 60% !important;
        margin-right: 20% !important;
        margin-left: 20% !important;
    }

    .modal-title {
        font-size: 1rem !important;
    }


}