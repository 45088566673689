.faq-title{
    margin-bottom: 1rem;
    width: 100%;
    text-align: left !important;
    background: #c39341cc !important;
}

.collapse-faq{
    margin-bottom: 1rem;
}

.contact__info-faq{
    border: 3px solid #c39341e5;
    border-radius: 1rem;
    padding: 2rem;
}

.contact__info-faq-title{
    font-size: 1.5rem;
    cursor: default;
    color: #c39341;
}

.contact__info-faq i {
    font-size: 3rem;
    
}

.contact__info-faq a {
    font-size: 1.2rem;
    text-decoration: none;
    color: #c39341;
}

.contact__info-faq a:hover {
    color: #ffc053;
}

.color-wp{
    color: rgb(0, 255, 0);
}

.color-mail{
    color: rgb(0, 102, 255);
}

@media only screen and (max-width: 1200px) {
    .contact__info-faq i {
        font-size: 2.1rem;
        
    }
    .contact__info-faq a {
        font-size: 1rem;
    }
    
  }

  @media only screen and (max-width: 996px) {
   .contact__info-faq{
    margin-left: 8rem;
    margin-right: 8rem;
   }
    .faq-img img {
        height: 13rem;
    }
    
  }
  @media only screen and (max-width: 770px) {
    .contact__info-faq{
     margin-left: 4rem;
     margin-right: 4rem;
    }
     
   }
   @media only screen and (max-width: 440px) {
    .contact__info-faq{
     margin-left: 1rem;
     margin-right: 1rem;
    }
     
   }