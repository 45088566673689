.modal__car__item {
    border: 2px solid #1a1a293c;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.modal__car__item-deneme {
    background: #0000fa3c;
    border: 2px solid #1a1a293c;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.modal__car__img img {
    height: 6rem;
    width: 9.5rem;
}

.modal__car__item-info span {
    font-size: 0.8rem;
}

.modal__car__item-info span i {
    color: #c39341e5;
    font-size: 0.9rem;
}

.modal__car__item-btn {
    border-radius: 0;
    border: none;
    outline: none;
    background: #c39341e5;
    color: #1a1a29;
    padding: 3px 0px;
    width: 50% !important;
}

.modal__car__item-btn:hover {
    background: #c39341c0;
}

.modal__car__item-btn a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
}

.modal__car__item-btn a:hover {
    color: #fff;
}

.modal__car__btn-rent {
    border-radius: 4px;
}

.modal__car__item-content h4 {
    font-size: 1rem;
}

.modal__car__item-content h6 {
    font-size: 0.8rem;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {

    .modal__car__item h4 {
        font-size: 0.8rem;
    }

    .modal__car__item h6 {
        font-size: 0.7rem;
    }

    .modal__car__img img {
        height: 5rem;
        width: 9rem;
    }

    .modal__car__item-info {
        flex-wrap: wrap;
    }

    .modal__car__item-info span {
        font-size: 0.7rem;
    }

    .modal__car__item-btn {
        padding: 3px 0px;
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 768px) {
    .modal__car__item-content h4 {
        font-size: 0.7rem;
    }

    .modal__car__item-content h6 {
        font-size: 0.6rem;
    }

    .modal__car__img img {
        height: 4.5rem;
        width: 7.8rem;
    }

    .modal__car__item-info span {
        font-size: 0.7rem;
    }

    .modal__car__item-info span i {
        font-size: 0.9rem;
    }

    .modal__car__item-btn {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 576px) {

    .modal__car__item-content h4 {
        font-size: 0.6rem;
    }

    .modal__car__item-content h6 {
        font-size: 0.6rem;
    }

    .modal__car__img img {
        height: 4rem;
        width: 4.3rem;
    }

    .modal__car__item-info span {
        font-size: 0.7rem;
    }

    .modal__car__item-info span i {
        font-size: 0.7rem;
    }

    .modal__car__item-btn {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 400px) {

    .modal__car__item-content h4 {
        font-size: 0.6rem;
    }

    .modal__car__item-content h6 {
        font-size: 0.6rem;
    }

    .modal__car__img img {
        height: 3.5rem;
        width: 4.3rem;
    }

    .modal__car__item-info span {
        font-size: 0.5rem;
    }

    .modal__car__item-info span i {
        font-size: 0.5rem;
    }

    .modal__car__item-btn {
        font-size: 0.5rem;
    }
}