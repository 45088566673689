.not-found-title   {
    color: #c39341e5 ;
    font-size: 5rem;
    font-weight: 900;
    cursor: default;
}

.not-found-desc{
    color: #c39341e5 ;
    font-size: 3rem;
    font-weight: 600;
    cursor: default;
}

@media only screen and (max-width: 1200px){
    .not-found-title   {
        font-size: 4rem;
    }
    
    .not-found-desc{
        font-size: 2rem;
    }
    .not-found-img{
        margin: auto;
    }
}

@media only screen and (max-width: 760px){
    .not-found-img{
        margin: auto;
        width: 80%;
    }
    .not-found-title   {
        font-size: 2.5rem;
    }
    
    .not-found-desc{
        font-size: 1rem;
    }
}