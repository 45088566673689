.select__car-modal{
    max-width: 80% !important;
    margin-right: 10% !important;
    margin-left: 10% !important;
}
.modal-content{
    background: #000 ;
    
}
.modal-header h5 {
    font-size: 1.5rem;
}
.stars {
    color: rgb(251, 109, 109);
    font-weight: 900;
    font-size: 1rem;
}
.bilgilendirme{
    color: rgb(249, 55, 55);
    font-size: 1rem;
}
.select__car-continue{
    background: #c39341e5 !important;
    color: #fff !important;
}

.select__car-cansel {
    background: #1a1a29eb !important;
    color: #fff !important;
}
.select__car-cansel:hover {
    background: #1a1a299d !important;
    color: #fff !important;
}
@media only screen and (max-width: 992px){
    .select__car-modal{
        max-width: 90% !important;
        margin-right: 5% !important;
        margin-left: 5% !important;
    }

    .modal-header h5 {
        font-size: 1.3rem;
    }
    .stars {
        font-size: 0.9rem;
    }
    .bilgilendirme{
        font-size: 1rem;
    }
}

/* @media only screen and (max-width: 768px){

} */

 @media only screen and (max-width: 576px) {
    .select__car-modal{
        max-width: 95% !important;
        margin-right: 2.5% !important;
        margin-left: 2.5% !important;
    }

    .modal-header h5 {
        font-size: 1rem;
    }

    
} 

@media only screen and (max-width: 450px) {
    .select__car-modal{
        max-width: 95% !important;
        margin-right: 2.5% !important;
        margin-left: 2.5% !important;
    }

    .modal-header h5 {
        font-size: 1rem;
    }

    .modal-body label {
        font-size: 0.8rem;
    }

    .form-control{
        font-size: .7rem !important;
    }
} 
