.social__link-icon {
  text-decoration: none;

  color: #c39341e5;
  font-weight: 500;
  font-size: 1.2rem;
}
.error {
  border: 2px solid #ff461d !important;
}
.social__link-icon:hover {
  font-weight: 600;
  color: #c39341;
}
.contact__btn {
  padding: 7px 15px;
  background: #c39341e5 !important;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
}
.contact__btn:hover{
  
  background: #c39341;
}

@media only screen and (max-width: 768px) {
  .contact__btn {
    margin-bottom: 40px;
    font-size: 0.8rem;
  }
}
