.form__group {
  width: 100%;
  margin-bottom: 0;
}

.datepicker-custom {
  width: 100%;
  height: 2.5rem;
  cursor: text;
  background: #fff;
  border: none;
  border-radius: 100px;
  font-size: 1rem;
  text-align: start;
  padding-left: 1rem;
  color: #000 !important;
}

.form__item-label {
  width: 100%;
  font-size: 1.2rem;
  color: #c39341e5;
}

.required_field {
  background-color: #c39341e5;
  border-radius: 1rem;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  
}

.form__group input {
  border: 1px solid #7c8a972a;
  padding: 8px 12px;
  border-radius: 100px;
  width: 100%;
  outline: none;
}
.form__group input::placeholder {
  color: #7c8a97;
}

.form {
  background:linear-gradient(rgba(44, 50, 91, 0.5), rgba(232, 232, 232, 0.5)), #1a1a29d7;
  padding-top: 55px;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px;
}

.select__group {
  width: 100%;
}

.select__group select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #7c8a972a;
  height: 2.5 rem;
  outline: none;
  color: #000;
  background-color: #fff;
  border-radius: 50px ;
  font-size: 1rem !important;
  text-align: start;
  padding-left: 1rem !important;
}

.select__group select option {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #7c8a972a;
  outline: none;
  color: #000;
  background-color: #fff;
  border-radius: 50px ;
  font-size: 1rem;
}

.hero__slider-section {
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
}

.hero__form {
  position: absolute;
  top: 2rem;
  z-index: 11;
  width: 30%;
  right: 9rem;
}

.form__row {
  background: #fff;
  box-shadow: 5px 5px 5px 5px #343449cf;
  border-radius: 50px;
}

.journey__time::before {
  content: "Journey time" ;
  margin-right: 0.8rem;
  color: #7c8a97;
}

.form__group__btn {
  width: 100%;
}

.find__car-btn {
  background: #c39341e5 !important;
  color: #fff !important;
  padding: 8px 15px;
  width: 70%;
  /* border: none;
  outline: none; */
  border-radius: 50px !important;
}

.find__car-btn:hover {
  background: #c393416a !important;
  color: #fff !important;
  
  
}
@media only screen and (max-width: 992px) {
  .find__cars-left {
    padding: 65px 22px;
  }

  .find__cars-left h2 {
    font-size: 1.5rem;
  }

  .form {
    padding: 30px 10px;
  }

  .form__group input,
  .select__group select {
    padding: 7px 10px;
   
  }

  .hero__form {
    bottom: -100px;
  }
}

@media only screen and (max-width: 768px) {
  .find__cars-left {
    padding-top: 20px;
    padding-bottom: 0;
    background: #fff;
    text-align: center;
  }

  .form {
    padding: 20px 10px;
  }
  .hero__form {
    position: absolute;
    top: 3rem;
    z-index: 11;
    width: 45%;
    right: 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .form__group {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }
  .hero__slider-section {
    margin-bottom: 360px;
  }

  .form__group input {
    font-size: 0.7rem !important;
  }

  .select__group {
    width: 100%;
  }

  .select__group select {
     height: 2.3rem;
     font-size: 0.9rem !important;
  }

  .datepicker-custom {
    height: 2.3rem;
    font-size: 0.9rem !important
  }

  .find__car-btn {
    font-size: 0.9rem !important;
  }

  .find__cars-left h2 {
    font-size: 1rem;
  }

  .hero__form {
    /* bottom: -3000px; */
    width: 90%;
    top: 18rem;
    left: 4%;
  }
  .form__item-label {
    font-size: 1rem;
  }
}
