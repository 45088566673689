.car__item {
  border: 2px solid #1a1a293c;
  padding: 20px;
  border-radius: 5px;
}

.car__img img {
  width:100%;
height:13rem;
object-fit:fill;
}

.car__item-info span i {
  color: #c39341e5;
  font-size: 1.1rem;
}

.car__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  background: #1a1a29;
  padding: 8px 0px;
}

.car__item-btn:hover {
  background: #1a1a29e4;
}

.car__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}
.car__item button {
  font-size: 1rem;
}
.car__item-btn a:hover {
  color: #fff;
}

.car__btn-rent {
  border-radius: 4px;
  background: #c39341e5;
}

.car__btn-rent:hover {
  background: #c39341f7;
}

.car__item-content h4 {
  font-size: 1.5rem;
}

.car__item-content h6 {
  font-size: 1rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .car__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  .car__item button {
    font-size: .8rem;
  }
  .car__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .car__item-info {
    flex-wrap: wrap;
  }

  .car__item-info span {
    font-size: 0.8rem;
  }

  .car__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .car__item h4 {
    font-size: 1rem;
  }
  
  .car__item h6 {
    font-size: 0.9rem;
  }

  .car__item-info span {
    font-size: 0.7rem;
  }

  .car__item-info span i {
    font-size: 0.9rem;
  }

  .car__item-btn {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 575px){
  .car__item {
    margin: 0 4rem 0 4rem;
  }
}

@media only screen and (max-width: 420px) {
  .car__item {
    margin: 0 1rem 0 1rem;
  }
}
