.slider__item {
  max-width: 100%;
  height: 650px;
}

.slider__item-01 {
  background: linear-gradient(rgba(255, 220, 124, 0.377), rgba(254, 190, 14, 0.152)),
    url("../assets/all-images/slider-img/slider-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.slider__item-02 {
  background: linear-gradient(rgba(255, 220, 124, 0.377), rgba(254, 190, 14, 0.152)),
    url("../assets/all-images/slider-img/slider-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-03 {
  background: linear-gradient(rgba(255, 220, 124, 0.377), rgba(254, 190, 14, 0.152)),
    url("../assets/all-images/slider-img/slider-3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__slider {
  overflow: hidden;
}

.slider__content {
  padding-top: 150px;
  width: 45%;
}

.slider__content h1 {
  font-size: 2.3rem;
  font-weight: 600;
}

.slider__content h4{
  font-size: 1.3rem;
  font-weight: 600;
}

.slider__content p{
  font-size: 1.1rem;
  color: #ffffffe5 !important;
  font-weight: 600;
}
.reserve__btn {
  background: #c39341e5 !important;
  color: #fff !important;
  border: none;
  outline: none;
  
}

.reserve__btn a {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  
}

.reserve__btn:hover {
  background: #c39341e5;
  color: #fff;
}

.reserve__btn a:hover {
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .slider__item {
    height: 530px;
    max-width: 100vw;
  }

  .slider__content {
    margin-top: -50px;
  }

  .slider__content h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .slider__item {
    height: 450px;
  }
  .slider__content {
    width: 45%;
  }
  .slider__content h1 {
    font-size: 1.5rem;
  }
  .slider__content p {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 576px) {
  .slider__item {
    height: 400px;
  }

  .slider__content h1 {
    font-size: 1.5rem;
  }

  .slider__content p {
    font-size: 0.9rem;
  }

  .slider__content {
    padding-top: 100px;
    width: 100%;
  }
}