.common__section {
  background: linear-gradient(rgba(255, 220, 124, 0.684), rgba(254, 190, 14, 0.453)),
    url("../assets/all-images/drive.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0px;
}

.common__section-h1{
  color: #1a1a29;
}
