.about__section-item {
    column-gap: 3rem;
    margin-top: 1.5rem;
}

.about__section-item p i {
    color: #c39341e5;
    font-size: 1.1rem;
}


@media only screen and (max-width: 992px) {
    .about__section-content h2 {
        font-size: 1.5rem;
    }

    .about__section-item {
        column-gap: 3rem;
    }

    .about__section-item p {
        font-size: 0.9rem;
        margin-bottom: 0;
    }

    .section__description i {
        font-size: 0.8rem !important;
    }

    .about__img {
        margin-top: 50px;
    }


}

@media only screen and (max-width: 768px) {
    .about__img {
        margin-top: 30px;
    }
    .about__section {
        margin-top: 1px !important ;
    }
}



