.booking__information-modal {
    max-width: 60% !important;
    margin-right: 20% !important;
    margin-left: 20% !important;
}

.booking__information-form {
    width: 100%;
}

.booking__information-formValid {
    width: 100%;
}

.booking-bilgilendirme {
    color: rgb(249, 55, 55);
    font-size: 1rem;
}

.booking__information-form input {
    padding: 8px 15px;
    border: 1px solid #7c8a972a;
    color: #7c8a97;
    width: 100%;
    height: 2.5rem;
    font-size: 0.9rem;
}

.booking__information-formValid input {
    padding: 8px 15px;
    border: 2px solid #ff461d;
    color: #7c8a97;
    width: 100%;
    height: 2.5rem;
    font-size: 0.9rem;
}

.booking__information-form select {
    width: 100%;
    padding: 10px 15px;
    color: #7c8a97;
    border: 1px solid #7c8a972a;
    border-radius: 4px;
    height: 2.5rem;
    font-size: 0.9rem;
}

.booking__information-formValid select {
    width: 100%;
    padding: 10px 15px;
    color: #7c8a97;
    border: 2px solid #ff461d;
    border-radius: 4px;
    height: 2.5rem;
    font-size: 0.9rem;
}

.booking__information-form input:focus,
.booking__information-form select:focus {
    outline: none;
    border: 2px solid #c39341e5;
}



.booking__information-formValid input:focus,
.booking__information-formValid select:focus {
    outline: none;
    border: 2px solid #ff461d;
}

.booking__information-reservation {
    background: #c39341e5 !important;
    color: #fff !important;
}

.booking__information-cansel {
    background: #1a1a29eb !important;
    color: #fff !important;
}

.booking__information-cansel:hover {
    background: #1a1a299d !important;
    color: #fff !important;
}

@media only screen and (max-width: 768px) {
    .booking__information-modal {
        max-width: 70% !important;
        margin-right: 15% !important;
        margin-left: 15% !important;
    }

    .booking__information-form {
        width: 100%;
    }
    .booking-bilgilendirme {
        font-size: .9rem;
    }

    .booking__information-form input,
    .textarea,
    .booking__information-form select {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 576px) {
    .booking__information-modal {
        max-width: 90% !important;
        margin-right: 5% !important;
        margin-left: 5% !important;
    }


}